<div class="wallet-password">
  <div class="content">
    <h2>Set your Recovery Password</h2>
    <h3>
      In order to create your wallet, we require you to set a recovery password that will ensure your wallet's security
      which will be used to access GAIMIN Smart Wallet.
    </h3>
    <form [formGroup]="form" class="form">
      <div class="form__field-group">
        <div class="password-field">
          <input
            formControlName="password"
            [type]="validatorsService.isPasswordVisible ? 'text' : 'password'"
            class="form__field"
            id="password"
            placeholder="Password"
            #password />
          <span
            (click)="validatorsService.togglePasswordVisibility()"
            class="icon"
            [ngClass]="{
              'icon-Closed-Eye': !validatorsService.isPasswordVisible,
              'icon-Eye': validatorsService.isPasswordVisible
            }"></span>
        </div>

        <ng-container *ngIf="passwordControl.invalid && passwordControl.dirty">
          <span *ngIf="passwordControl.hasError('required')" class="form__error"> Password should be present </span>

          <span
            *ngIf="
              passwordControl.hasError('minlength') || passwordControl.hasError('maxlength');
              else password_allowedPattern
            "
            class="form__error">
            Password should have
            <mark>{{ validatorsService.passwordMinLength }} - {{ validatorsService.passwordMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ passwordControl.value!.length }}</mark>
          </span>

          <ng-template #password_allowedPattern>
            <ng-container *ngIf="passwordControl.hasError('allowedPattern')">
              <span class="form__error">{{ validatorsService.allowedPatternError }}</span>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
      <div class="form__field-group">
        <div class="password-field">
          <input
            formControlName="repeatPassword"
            [type]="validatorsService.isRepeatPasswordVisible ? 'text' : 'password'"
            class="form__field"
            id="repeatPassword"
            placeholder="Confirm password"
            #repeatPassword />
          <span
            (click)="validatorsService.togglePasswordVisibility(true)"
            class="icon"
            [ngClass]="{
              'icon-Closed-Eye': !validatorsService.isRepeatPasswordVisible,
              'icon-Eye': validatorsService.isRepeatPasswordVisible
            }"></span>
        </div>

        <ng-container *ngIf="form.get('repeatPassword')!.dirty">
          <span *ngIf="form.get('repeatPassword')!.hasError('required'); else password_match" class="form__error">
            Password should be present
          </span>

          <ng-template #password_match>
            <span *ngIf="form.invalid && form.hasError('fieldsMatch')" class="form__error">
              The password and confirmation password do not match.
            </span>
          </ng-template>
        </ng-container>
      </div>

      <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Set password</button>
    </form>
  </div>
  <img class="illustration" src="assets/icons/smart-wallet.png" alt="robot" />
</div>
