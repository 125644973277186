import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { UserSignInRequest } from '../../shared/intarfaces';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss']
})
export class SigninPageComponent {
  theme$ = this.themeService.theme$;

  form: FormGroup = new FormGroup<any>({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    public validatorsService: ValidatorsService,
    public authService: AuthService,
    private themeService: ThemeService
  ) {
    this.authService.checkLoginStatus();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const passwordHash = await this.authService.hashPassword(this.form.value.password, this.form.value.email);
    const passwordFallbackHash = await this.authService.hashPassword(
      this.form.value.password,
      this.form.value.email,
      false
    );

    const userSignInRequest: UserSignInRequest = {
      email: this.form.value.email,
      password: passwordHash,
      fallbackPassword: passwordFallbackHash
    };

    this.authService.sendLoginRequest(userSignInRequest).subscribe((response) => {
      if (response.success && response.data) {
        this.authService.successAuthorize(response.data);
      }
    });
  }
}
