import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { AuthTokens } from '../../shared/intarfaces';
import { AUTH_METHODS, AUTH_TYPES } from '../../shared/enums';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'sso-auth-page',
  templateUrl: './sso-auth-page.component.html',
  styleUrls: ['./sso-auth-page.component.scss']
})
export class SsoAuthPageComponent implements OnInit {
  @AutoUnsubscribe()
  routeParamsSub: Subscription | undefined;
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  theme$ = this.themeService.theme$;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly themeService: ThemeService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.routeParamsSub = this.route.params.subscribe((params: Params) => {
      const ssoType = params['type'];
      const method = params['method'];

      this.queryParamsSub = this.route.queryParams.subscribe((queryParams) => {
        const code = queryParams['code'];

        if (!ssoType || !method || !code) {
          this.router.navigateByUrl('/');
        }

        let request;

        switch (ssoType) {
          case AUTH_TYPES.google:
            switch (method) {
              case AUTH_METHODS.signin:
                request = this.authService.sendGoogleAuthRequest(code, AUTH_METHODS.signin);
                break;
              case AUTH_METHODS.signup:
                request = this.authService.sendGoogleAuthRequest(code, AUTH_METHODS.signup);
                break;
              case AUTH_METHODS.connect:
                request = this.authService.connectGoogleRequest(code);
                break;
            }
            break;

          case AUTH_TYPES.discord:
            switch (method) {
              case AUTH_METHODS.signin:
                request = this.authService.sendDiscordAuthRequest(code, AUTH_METHODS.signin);
                break;
              case AUTH_METHODS.signup:
                request = this.authService.sendDiscordAuthRequest(code, AUTH_METHODS.signup);
                break;
              case AUTH_METHODS.connect:
                request = this.authService.connectDiscordRequest(code);
                break;
            }
            break;
        }

        request?.subscribe({
          next: (response) => {
            if (response.success) {
              if ('data' in response && response.data) {
                this.authService.successAuthorize(response.data as AuthTokens);
              } else {
                this.toastrService.success('Account connected successfully!');
                this.router.navigateByUrl('/profile');
              }
            } else {
              this.router.navigateByUrl('/');
            }
          },
          error: () => {
            this.router.navigateByUrl('/');
          }
        });
      });
    });
  }
}
