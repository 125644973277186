import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../../services/validators.service';

@Component({
  selector: 'wallet-password-modal',
  templateUrl: './wallet-password-modal.component.html',
  styleUrls: ['./wallet-password-modal.component.scss']
})
export class WalletPasswordModalComponent implements OnInit {
  @Output() confirmRequest: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', this.validatorsService.passwordValidators(false, false)),
      repeatPassword: new FormControl('', [Validators.required])
    },
    { validators: this.validatorsService.fieldsMatchValidator('password', 'repeatPassword', true) }
  );

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private dialogRef: MatDialogRef<WalletPasswordModalComponent>,
    public validatorsService: ValidatorsService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close();
    this.confirmRequest.emit(this.form.value.password);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
