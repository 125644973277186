import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import {
  Response,
  StatusResponse,
  UserForgotPasswordRequest,
  UserInfo,
  UpdateUserInfo,
  UpdateOpenfortPlayerId,
  UserAvatar,
  ChangePasswordRequest,
  UserResetPasswordRequest
} from '../intarfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>({} as UserInfo);

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getUserInfo() {
    this.getUserInfoRequest().subscribe((response) => {
      if (response.success && response.data) {
        this.userInfo$.next(response.data);
      }
    });
  }

  changePasswordRequest(data: ChangePasswordRequest): Observable<StatusResponse> {
    return this.http.patch<StatusResponse>(`${environment.gaiminApi}/user/password/update`, data, {
      headers: this.authService.authorizationHeader()
    });
  }

  forgotPasswordRequest(data: UserForgotPasswordRequest): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.gaiminApi}/user/password/request-reset`, data);
  }

  resetPasswordRequest(data: UserResetPasswordRequest): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.gaiminApi}/user/password/confirm-reset`, data);
  }

  getUserInfoRequest(): Observable<Response<UserInfo>> {
    return this.http.get<any>(environment.gaiminApi + '/user/me', {
      headers: this.authService.authorizationHeader()
    });
  }

  confirmationTokenRequest(confirmationToken: string): Observable<StatusResponse> {
    return this.http.get<StatusResponse>(
      `${environment.gaiminApi}/user/sign-up/confirm?confirmationToken=${confirmationToken}`
    );
  }

  updateUserInfoRequest(data: UpdateUserInfo | UpdateOpenfortPlayerId): Observable<Response<UserInfo>> {
    return this.http.patch<Response<UserInfo>>(environment.gaiminApi + '/user/update', data, {
      headers: this.authService.authorizationHeader()
    });
  }

  updateUserAvatarRequest(data: UserAvatar): Observable<Response<UserInfo>> {
    return this.http.patch<Response<UserInfo>>(`${environment.gaiminApi}/user/update`, data, {
      headers: this.authService.authorizationHeader()
    });
  }

  uploadAvatarImageRequest(formData: any): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${environment.gaiminApi}/images`, formData, {
      headers: this.authService.authorizationHeader()
    });
  }

  removeUserRequest(): Observable<StatusResponse> {
    return this.http.delete<StatusResponse>(`${environment.gaiminApi}/user/me`, {
      headers: this.authService.authorizationHeader()
    });
  }
}
