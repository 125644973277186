import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../../services/validators.service';
import { WalletService } from 'src/app/shared/services/wallet.service';

@Component({
  selector: 'wallet-password-modal',
  templateUrl: './wallet-password-modal.component.html',
  styleUrls: ['./wallet-password-modal.component.scss']
})
export class WalletPasswordModalComponent {

  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', this.validatorsService.passwordValidators(false, false)),
      repeatPassword: new FormControl('', [Validators.required])
    },
    { validators: this.validatorsService.fieldsMatchValidator('password', 'repeatPassword', true) }
  );

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private dialogRef: MatDialogRef<WalletPasswordModalComponent>,
    public validatorsService: ValidatorsService,
    private walletService: WalletService
  ) {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const recoveryPassword = this.form.value.password;

    if (recoveryPassword) {
      this.walletService.setRecoveryPassword(recoveryPassword);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
