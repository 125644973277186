import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  theme$ = this.themeService.theme$;

  form: FormGroup = new FormGroup({
    token: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', this.validatorsService.passwordValidators(true, false))
  });

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public validatorsService: ValidatorsService,
    public authService: AuthService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      if (token) {
        this.form.get('token')?.setValue(token);
      }
      const email = params['email'];
      if (email) {
        this.form.get('email')?.setValue(email);
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.authService.hashPassword(this.form.value.password, atob(this.form.value.email)).then((hash) => {
      const resetPasswordRequest = { password: hash, token: this.form.value.token };
      this.authService.resetPasswordRequest(resetPasswordRequest).subscribe((response) => {
        if (response.success) {
          this.toastrService.success('Password successfully changed!');
          this.router.navigateByUrl('/signin');
        }
      });
    });
  }
}
