import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../shared/services/user.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
  form: FormGroup = new FormGroup<any>({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  theme$ = this.themeService.theme$;

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private themeService: ThemeService,
    public userService: UserService
  ) {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.userService.forgotPasswordRequest(this.form.value).subscribe((response) => {
      if (response.success) {
        this.toastrService.success('Password reset link sent! Please check your email.');
        this.router.navigateByUrl('/signin');
      }
    });
  }
}
