import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const utmSource = params['utmSource'];
      if (utmSource) {
        this.authService.utmSourceId = utmSource;
      }
      const returnUrl = params['returnUrl'];
      if (returnUrl) {
        this.authService.returnUrl = returnUrl;
      }
    });
  }
}
