import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import {
  Response,
  StatusResponse,
  UserForgotPasswordRequest,
  UserInfo,
  UpdateUserInfo,
  ChangePasswordRequest
} from '../intarfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo$ = this.authService.userInfo$;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  loadUserInfo(): void {
    this.authService.proceedLoginFlow();
  }

  changePasswordRequest(data: ChangePasswordRequest): Observable<StatusResponse> {
    return this.http.patch<StatusResponse>(`${environment.gaiminApi}/user/password/update`, data, {
      headers: this.authService.authorizationHeader()
    });
  }

  forgotPasswordRequest(data: UserForgotPasswordRequest): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.gaiminApi}/user/password/request-reset`, data);
  }

  confirmationTokenRequest(confirmationToken: string): Observable<StatusResponse> {
    return this.http.get<StatusResponse>(
      `${environment.gaiminApi}/user/sign-up/confirm?confirmationToken=${confirmationToken}`
    );
  }

  updateUserInfoRequest(data: UpdateUserInfo): Observable<Response<UserInfo>> {
    return this.http.patch<Response<UserInfo>>(environment.gaiminApi + '/user/update', data, {
      headers: this.authService.authorizationHeader()
    });
  }

  uploadAvatarImageRequest(formData: FormData): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${environment.gaiminApi}/upload/img`, formData, {
      headers: this.authService.authorizationHeader()
    });
  }
}
