<div class="container">
  <div class="terms">
    <h2 class="back-title">
      <div routerLink="/">
        <span class="icon icon-Arrow-Left"></span>
      </div>
    </h2>
    <h1 class="title">GAIMIN GLADIATORS’ TERMS AND CONDITIONS FOR THE SUPPLY OF DIGITAL ASSETS (these “Terms”)</h1>
    <div class="html-content">
      <h3>Where to find information about us and our digital assets</h3>
      <p>
        You can find everything you need to know about us, Gaimin Gladiators Limited, and the digital assets we have for
        sale on our website
        <a href="https://colosseum.gaimingladiators.gg/" target="_blank" rel="noopener noreferrer">
          colosseum.gaimingladiators.gg
        </a>
        before you order. We also confirm the key information to you in writing after your order by email and via your
        online account.
      </p>

      <h3>Acceptance of these Terms</h3>
      <ol>
        <li>You acknowledge that these Terms shall govern the supply of a digital asset to you.</li>
        <li>
          When purchasing a digital asset from us via our website, you will be required to indicate your acceptance of
          these Terms prior to submitting your order.
        </li>
      </ol>

      <h3>We only accept orders when we’ve checked them</h3>
      <ol>
        <li>
          Your submission of an order is your offer to purchase the digital asset for the price outlined on our website.
          Initially, we will contact you to confirm we’ve received your order and then, if we have accepted your offer
          to purchase the digital asset, we shall contact you again to confirm that we’ve accepted your order.
        </li>
        <li>
          You agree that you will not submit an order to purchase a digital asset if you are less than 18 years of age
          or if you are a citizen or resident (i) of any country that prohibits the purchase of crypto/blockchain assets
          or the holding of crypto/blockchain assets; (ii) of a country that is included in any international, regional
          or other sanction list or measures; or (iii) of a country that is subject to any sanctions administered or
          enforced by any country or government or international authority.
        </li>
        <li>
          Sometimes we reject orders (before we have contacted you to outline our acceptance), for example, because we
          can’t verify your age or certain details relating to your identity, because you are attempting to purchase
          more digital assets than we allow a single individual to purchase, or because of where you are located or
          because the product was mispriced by us. When this happens, we will let you know as soon as possible and
          refund any sums you have already paid.
        </li>
        <li>
          Sometimes, we may need you to provide additional information to help us to verify your identity. We shall
          reject your order if you are unable to furnish us with any documents or information when requested by us.
        </li>
      </ol>

      <h3>What and when we will charge you</h3>
      <p>
        We will charge you the price outlined on our website and require payment by you at the time that you submit your
        order to us.
      </p>

      <h3>Digital assets</h3>
      <ol>
        <li>
          The digital assets available for purchase via our website are blockchain-based utility tokens that exist on a
          blockchain. The blockchain utilised for each digital asset will be set out on the webpage outlining the
          digital asset. These digital assets technically constitute a transferable representation of attributed rights
          and functions. In these terms, we often refer to these rights and functions as the digital asset's inherent
          “utility”.
        </li>
        <li>
          You acknowledge that different digital assets have different utility. The utility of the digital assets
          available will be outlined on our website and accessible to you before your submission of an order.
        </li>
        <li>
          Our digital assets are made available to build the connection between Gaimin Gladiators’ fans and the Gaimin
          Gladiators esports organisation and teams. It is our intention and desire that these digital assets are
          purchased by the supporters of Gaimin Gladiators and not purchased by you with the intention of receiving
          commercial or financial gain.
        </li>
        <li>
          You acknowledge that:
          <ol>
            <li>
              our digital assets are not financial instruments, or shares in Gaimin Gladiators Limited, our esports
              organisation, or any of our teams or players. No digital asset owner shall be entitled to any ownership
              rights in Gaimin Gladiators Limited, our esports organisation, or any of our teams or players, nor do they
              convey a right to receive or participate in future profits, revenue streams, proceeds, or other forms of
              financial gain;
            </li>
            <li>
              the Terms are not a prospectus of any sort, we are not soliciting for investment and these Terms do not
              relate to the offering of securities in any jurisdiction;
            </li>
            <li>
              and being an owner of a digital asset does not mean you have a licence or rights in respect of any
              intellectual property rights, rights of publicity, or equivalent rights in or related to Gaimin Gladiators
              Limited, our esports organisation, or any of our teams or players.
            </li>
          </ol>
        </li>
      </ol>

      <h3>Ongoing use of digital assets</h3>
      <ol>
        <li>
          You acknowledge that, when you wish to exercise an underlying right, and/or utilise an underlying function of,
          a digital asset, this shall be subject to reasonable restrictions in respect of availability and potentially
          additional usage requirements.
        </li>
        <li>
          You acknowledge that, as per section 12, we may change a digital asset’s underlying rights and functions
          provided that the new rights and/or functions are of a comparable nature and value and if we provide you with
          notice of any such change. This is necessary as for multiple different reasons we need the ability to remain
          flexible with each digital asset’s underlying rights or functions.
        </li>
        <li>
          When you utilise a digital asset for its underlying rights or functions, the digital asset is not burned,
          consumed, transferred, or otherwise surrendered, but will remain with you as your property. It is not used as
          a means of payment or a transfer of value, it simply unlocks the utility made available by us, subject to
          section 6.1.
        </li>
        <li>
          We may suspend the utility relating to a digital asset if we suspect any of the following:
          <ol>
            <li>fraud, cheating, or misuse of your account on our website;</li>
            <li>
              any irregular or other unlawful activity associated with a digital asset or your account on our website;
            </li>
            <li>if the digital asset is held for the benefit or account of third parties; or</li>
            <li>any violation of these Terms or any applicable laws and regulations by you.</li>
          </ol>
        </li>
      </ol>

      <h3>Your receipt of a digital asset</h3>
      <ol>
        <li>
          After we have accepted your order for a digital asset, we shall arrange for the transfer of the digital asset
          to you as soon as practicably possible. If you have connected a third-party crypto asset wallet to our website
          then the digital asset will be transferred to that crypto asset wallet automatically. If you do not connect a
          third party crypto asset wallet, then the digital asset shall be stored in your account on our website, but
          you shall be entitled to transfer it to a third-party crypto asset wallet at any point. You acknowledge that
          you bear the risk for any digital asset that remains unsecured in your online account or for any transfer of a
          digital asset to a third-party crypto asset wallet.
        </li>
        <li>
          You acknowledge that any digital asset stored in your account on our website is stored there at your own risk
          and, whilst we use reasonable efforts to protect the integrity of our website and the accounts of users, we
          shall have no liability to you if a third party unlawfully accesses your account and take possession of any
          digital asset belonging to you.
        </li>
        <li>
          You agree that you shall bear the sole responsibility for any tax implications related to your purchase as
          well as the ongoing ownership and use of any digital asset purchased from us.
        </li>
      </ol>

      <h3>We’re not responsible for delays outside our control</h3>
      <p>
        If our supply of the digital asset is delayed by an event outside our control, we will contact you as soon as
        possible to let you know and do what we can to reduce the delay. As long as we do this, we won’t compensate you
        for the delay, but if the delay is likely to be substantial, you can contact us to end the contract, provided we
        can terminate the transfer of ownership of the relevant digital asset before such transfer takes effect, you can
        receive a refund.
      </p>

      <h3>You have no right to change your mind after you receive your digital asset</h3>
      <p>
        From the moment you receive ownership of your digital asset, you have the opportunity to both actively and
        passively benefit from your ownership of the digital asset. As a result, you have no right to change your mind
        and transfer ownership of the digital asset back to us after you have received ownership of it.
      </p>

      <h3>You have rights if there is something wrong with your digital asset</h3>
      <ol>
        <li>If you think there is something wrong with your digital asset, you must contact us.</li>
        <li>
          We shall be responsible for ensuring that your digital asset is as described and that you can benefit from the
          outlined utility (or utility of a comparable nature or value). Where this is not the case you are entitled to
          receive a replacement digital asset. Where this is not possible, you are entitled to get a pro-rata refund of
          the price paid. The refund amount will depend upon any benefit that it is reasonably determined you have
          actively or passively received as a result of your ownership of the digital asset prior to you contacting us.
        </li>
        <li>
          Other than as set out in this section 10, we shall have no other liability to you for any losses you suffer as
          a result of there being something wrong with your digital asset (or any underlying utility related to the
          asset).
        </li>
      </ol>

      <h3>Changes we can make to the digital asset and/or these terms before you receive your digital asset</h3>
      <ol>
        <li>
          <strong>Changes we can always make before you receive the digital asset.</strong> We can change (i) these
          terms or (ii) a digital asset (but not the value of its underlying utility), following your purchase but
          before you are in receipt of the digital asset:
          <ol>
            <li>to reflect changes in relevant laws and regulatory requirements; and</li>
            <li>
              to implement minor technical adjustments and improvements where these changes don’t affect the digital
              asset's underlying utility.
            </li>
          </ol>
        </li>
        <li>
          <strong>Changes we can only make if we give you notice and an option to terminate.</strong> If, prior to
          providing you with the digital asset, we are to change these terms (other than for the reason outlined in
          section 8.1.1), we will notify you and re-confirm that you wish to still go ahead with your purchase. If you
          state that you wish to terminate/cancel your purchase as a result, we shall issue you with a full refund.
        </li>
      </ol>

      <h3>Changes we can make to the digital asset at any time</h3>
      <p>
        We can, upon providing you with written notice (which may include posting a notification to our website) change
        the underlying rights or functions of a digital asset at any time (including after your receipt of the digital
        asset) provided that the new underlying rights or functions are of a reasonably comparable nature and value to
        the rights or functions that are replaced.
      </p>

      <h3>We don’t compensate you for any losses caused by us or the digital assets</h3>
      <p>This includes (but is not restricted to), any loss that is:</p>
      <ol>
        <li>
          <strong>Unexpected.</strong> It was not obvious that it would happen and nothing you said to us before we
          accepted your order meant we should have expected it (so, in the law, the loss was unforeseeable).
        </li>
        <li>
          <strong>Caused by a delaying event outside our control.</strong> As long as we have taken the steps set out in
          section 8.
        </li>
        <li><strong>Avoidable.</strong> Something you could have avoided by taking reasonable action.</li>
        <li>
          <strong>A business loss.</strong> It relates to your use of a digital asset for the purposes of your trade,
          business, craft or profession.
        </li>
      </ol>

      <h3>We use your personal data as set out in our Privacy Notice</h3>
      <p>How we use any personal data you give us is set out in our Privacy Notice.</p>

      <h3>You have several options for resolving disputes with us</h3>
      <ol>
        <li>
          <strong>Resolving disputes without going to court.</strong> Alternative dispute resolution is an optional
          process where an independent body considers the facts of a dispute and seeks to resolve it, without you having
          to go to court. You can submit a complaint to the UK Government-backed Jurisdiction Taskforce by following the
          Digital Dispute Resolution Rules set out here.
        </li>
        <li>
          <strong>You can go to court.</strong> These terms are governed by English law and wherever you live you can
          bring claims against us in the English courts. We can also bring a claim against you in the courts of the
          country you live in.
        </li>
      </ol>

      <h3>Other important terms</h3>
      <ol>
        <li>
          <strong
            >We can transfer our contract with you so that a different organisation is responsible for supplying your
            digital asset or facilitating any underlying utility related to the digital asset.</strong
          >
          We’ll tell you in writing if this happens and we’ll ensure that the transfer won’t affect your rights under
          the contract
        </li>
        <li>
          <strong>You can transfer your digital asset (and this contract) to someone else.</strong> Unless otherwise
          outlined when you purchase a digital asset, you may transfer the ownership of a digital asset to a new owner,
          and the right to use or access any underlying utility related to the asset shall transfer also. We may require
          the new owner to prove you transferred the digital asset to them.
        </li>
        <li>
          <strong>Nobody else has any rights under this contract.</strong> This contract is between you and us. Nobody
          else can enforce it and neither of us will need to ask anybody else to sign off on ending or changing it.
        </li>
        <li>
          <strong>If a court invalidates some of this contract, the rest of it will still apply.</strong> If a court or
          other authority decides that some of these terms are unlawful, the rest will continue to apply.
        </li>
        <li>
          <strong>Even if we delay in enforcing this contract, we can still enforce it later.</strong> We might not
          immediately chase you for not doing something (like paying) or for doing something you’re not allowed to, but
          that doesn’t mean we can’t do it later.
        </li>
      </ol>
    </div>
  </div>
</div>
