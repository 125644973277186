import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;

  private readonly DESCRIPTION_EXCLUSION_LIST: string[] = ['User with this username already exists'];

  constructor(
    private readonly authService: AuthService,
    private readonly toastrService: ToastrService
  ) {}

  /**
   * Intercepts HTTP requests to add authorization token and handle errors.
   * @param {HttpRequest<unknown>} request - The outgoing HTTP request.
   * @param {HttpHandler} next - The next interceptor in the chain.
   * @returns {Observable<HttpEvent<unknown>>} - An observable of the HTTP event.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body != null && !event.body.success) {
          if (event.body.error?.description != null && event.body.error?.type !== 'AUTHENTICATION') {
            if (!this.DESCRIPTION_EXCLUSION_LIST.includes(event.body.error?.description)) {
              this.toastrService.error(event.body.error?.description);
            }
          }
          if (event.body.error?.type === 'AUTHENTICATION') {
            if (event.url?.includes(environment.gaiminApi + '/auth/token/refresh')) {
              this.logoutProcess();
            } else {
              this.handle401Error();
            }
          }
        }
      }),
      catchError((error) => {
        return throwError(() => {
          this.toastrService.error(error.message);
        });
      })
    );
  }

  /**
   * Handles 401 errors by attempting to refresh the token.
   */
  private handle401Error() {
    if (this.isRefreshing) {
      return;
    }
    this.isRefreshing = true;
    const refreshToken = this.authService.refreshToken;

    if (refreshToken) {
      return this.authService
        .refreshTokenRequest()
        .pipe(
          tap((response) => {
            this.isRefreshing = false;
            window.location.reload();
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.logoutProcess();
            return throwError(() => error);
          })
        )
        .subscribe();
    } else {
      console.log('Refresh token doesn`t exist');
      this.logoutProcess();
      return;
    }
  }

  /**
   * Logs out the user and disconnects from the wallet.
   */
  private logoutProcess() {
    this.authService.logout();
  }
}
