<div class="container">
  <h1 class="title">User Profile</h1>

  <div class="profile">
    <div class="profile__data">
      <div class="profile__data-avatar">
        <img
          class="avatar"
          [src]="(userService.userInfo$ | async)?.imageUrl"
          onError="this.src = 'assets/icons/default-user-image.png'"
          alt="Avatar" />
        <span class="icon-Edit" (click)="onAvatarEdit()"></span>
      </div>

      <div class="profile__data-name">
        {{ (userService.userInfo$ | async)?.firstName }} {{ (userService.userInfo$ | async)?.lastName }}
      </div>
    </div>
    <div class="profile__actions">
      <div class="profile__actions-logout" (click)="logout()">Sign out</div>
      <div class="profile__actions-remove" (click)="removeUser()">Remove user</div>
    </div>
  </div>

  <form
    *ngIf="(userService.userInfo$ | async)?.userId"
    action=""
    class="form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()">
    <div class="form__field-row">
      <label for="userId">User ID</label>
      <div class="form__field-group">
        <input class="form__field" id="userId" formControlName="userId" #userId placeholder="User ID" />
      </div>
    </div>
    <div class="form__field-row">
      <label for="firstName">First Name</label>
      <div class="form__field-group">
        <input class="form__field" id="firstName" formControlName="firstName" #firstName placeholder="First Name" />
        <ng-container *ngIf="form.get('firstName')?.invalid && form.get('firstName')?.touched">
          <span *ngIf="form.get('firstName')?.hasError('required')" class="form__error">
            First Name should be present
          </span>

          <span
            class="form__error"
            *ngIf="
              form.get('firstName')?.hasError('minlength') || form.get('firstName')?.hasError('maxlength');
              else firstName_pattern
            ">
            First Name should have
            <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ (form.get('firstName')?.value).length }}</mark>
          </span>

          <ng-template #firstName_pattern>
            <span *ngIf="form.get('firstName')?.hasError('pattern')" class="form__error">
              First Name should have only latin characters, hyphen and
              <mark>1</mark>
              space. It must start and end with a Latin character.
            </span>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="form__field-row">
      <label for="lastName">Last Name</label>
      <div class="form__field-group">
        <input class="form__field" id="lastName" formControlName="lastName" #lastName placeholder="Last Name" />
        <ng-container *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched">
          <span *ngIf="form.get('lastName')?.hasError('required')" class="form__error"
            >Last Name should be present</span
          >

          <span
            class="form__error"
            *ngIf="
              form.get('lastName')?.hasError('minlength') || form.get('lastName')?.hasError('maxlength');
              else lastName_pattern
            ">
            Last Name should have
            <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ (form.get('lastName')?.value).length }}</mark>
          </span>

          <ng-template #lastName_pattern>
            <span *ngIf="form.get('lastName')?.hasError('pattern')" class="form__error">
              Last Name should have only latin characters, hyphen and
              <mark>1</mark>
              space. It must start and end with a Latin character.
            </span>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="form__field-row">
      <label for="lastName">Email</label>
      <div class="form__field-group">
        <input class="form__field" id="email" formControlName="email" #lastName placeholder="Email" />
      </div>
    </div>
    <div class="form__field-row">
      <label for="username">Username</label>
      <div class="form__field-group">
        <input class="form__field" id="username" formControlName="username" #username placeholder="Username" />
        <ng-container *ngIf="form.get('username')?.invalid && form.get('username')?.touched">
          <span
            *ngIf="
              form.get('username')?.hasError('minlength') || form.get('username')?.hasError('maxlength');
              else username_pattern
            "
            class="form__error">
            Username should have
            <mark>{{ usernameMinLength }} - {{ usernameMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ (form.get('username')?.value).length }}</mark>
          </span>

          <ng-template #username_pattern>
            <span *ngIf="form.get('username')?.hasError('pattern'); else username_not_unique" class="form__error">
              Username can contain Latin characters, digits, and symbols, and cannot start or end with a special
              character.
            </span>
          </ng-template>

          <ng-template #username_not_unique>
            <span *ngIf="form.get('username')?.hasError('notUnique')" class="form__error">
              This username is already taken
            </span>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="form__field-row connect">
      <h3>Connect social media</h3>

      <div class="connect__item">
        <div class="connect-soc-name"><img src="assets/icons/social/google-icon.svg" alt="Google" /> Google</div>
        <div class="connect-user">
          <ng-container *ngIf="(userService.userInfo$ | async)?.googleUserDto">
            <img
              [src]="(userService.userInfo$ | async)?.googleUserDto?.imageUrl"
              onError="this.src = 'assets/icons/default-user-image.png'"
              alt="Google Avatar" />
            <span class="connect-user-name">{{ (userService.userInfo$ | async)?.googleUserDto?.username }}</span>
            <span
              *ngIf="
                (userService.userInfo$ | async)?.passwordUserDto?.hasPassword ||
                (userService.userInfo$ | async)?.discordUserDto
              "
              class="connect-user-btn"
              (click)="disconnectGoogle()"
              >Disconnect</span
            >
          </ng-container>
          <a *ngIf="!(userService.userInfo$ | async)?.googleUserDto" class="connect-user-btn" [href]="ssoGoogleAuthUrl"
            >Connect</a
          >
        </div>
      </div>
      <div class="connect__item">
        <div class="connect-soc-name"><img src="assets/icons/social/discord-icon.svg" alt="Discord" /> Discord</div>
        <div class="connect-user">
          <ng-container *ngIf="(userService.userInfo$ | async)?.discordUserDto">
            <img
              [src]="(userService.userInfo$ | async)?.discordUserDto?.imageUrl"
              onError="this.src = 'assets/icons/default-user-image.png'"
              alt="Discord Avatar" />
            <span class="connect-user-name">{{ (userService.userInfo$ | async)?.discordUserDto?.username }}</span>
            <span
              *ngIf="
                (userService.userInfo$ | async)?.passwordUserDto?.hasPassword ||
                (userService.userInfo$ | async)?.googleUserDto
              "
              class="connect-user-btn"
              (click)="disconnectDiscord()"
              >Disconnect</span
            >
          </ng-container>
          <a
            *ngIf="!(userService.userInfo$ | async)?.discordUserDto"
            class="connect-user-btn"
            [href]="ssoDiscordAuthUrl"
            >Connect</a
          >
        </div>
      </div>
    </div>

    <div
      *ngIf="(userService.userInfo$ | async)?.passwordUserDto?.hasPassword; else setPasswordTemplate"
      class="form__field-row change-password">
      <label>Change Password</label>
      <button class="button cancel" (click)="changePassword($event)">Change password</button>
    </div>
    <ng-template #setPasswordTemplate>
      <div class="form__field-row change-password">
        <label>Set Password</label>
        <button class="button cancel" (click)="setPassword($event)">Set password</button>
      </div>
    </ng-template>

    <div class="form__field-row">
      <button class="button" [disabled]="!form.valid">Save</button>
    </div>
  </form>

  <app-loader></app-loader>
</div>
