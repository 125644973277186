import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const utmSource = params['utmSource'];
      if (utmSource) {
        this.authService.utmSourceId = utmSource;
      }

      const userData = params['user'];
      if (userData) {
        this.authService.userData = userData;
      }
      let theme = params['theme'];
      if (theme) {
        if (theme !== 'dark' && theme !== 'light') {
          theme = 'dark';
        }

        this.themeService.setTheme(theme);
      } else {
        this.themeService.setTheme(this.themeService.themeSnapshot);
      }
    });
  }
}
