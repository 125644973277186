<div class="auth">
  <img *ngIf="(theme$ | async) === 'dark'; else light" class="auth__logo" src="assets/logo.svg" alt="Gaimin" />
  <ng-template #light>
    <img class="auth__logo" src="assets/light-logo.svg" alt="Gaimin" />
  </ng-template>

  <div class="auth__title">
    <h1>Sign in</h1>
    <h2>
      Do not have an account?
      <span routerLink="/signup">Sign up</span>
    </h2>
  </div>

  <form [formGroup]="form" class="form">
    <div class="form__field-group">
      <label for="email">Email</label>
      <input formControlName="email" class="form__field" type="text" placeholder="" id="email" />

      <ng-container *ngIf="form.get('email')!.invalid && form.get('email')!.dirty">
        <span *ngIf="form.get('email')!.hasError('required')" class="form__error"> Email should be present </span>
        <span *ngIf="form.get('email')!.hasError('email')" class="form__error"> Valid email address required </span>
      </ng-container>
    </div>

    <div class="form__field-group">
      <label for="password">Password</label>
      <div class="password-field">
        <input
          formControlName="password"
          [type]="validatorsService.isPasswordVisible ? 'text' : 'password'"
          class="form__field"
          id="password"
          #password />
        <span
          (click)="validatorsService.togglePasswordVisibility()"
          class="icon"
          [ngClass]="{
            'icon-Closed-Eye': !validatorsService.isPasswordVisible,
            'icon-Eye': validatorsService.isPasswordVisible
          }"></span>
      </div>
      <span *ngIf="form.get('password')!.hasError('required') && form.get('password')!.dirty" class="form__error">
        Password should be present
      </span>
    </div>

    <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Sign in</button>
  </form>

  <div class="auth__divider"><span>OR</span></div>

  <app-socials-login [isRegisterForm]="false"></app-socials-login>

  <span class="auth__link">
    <a [routerLink]="'/forgot-password'">Forgot password?</a>
  </span>

  <app-loader></app-loader>
</div>
