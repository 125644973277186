export enum AUTH_METHODS {
  signin = 'signin',
  signup = 'signup',
  connect = 'connect'
}

export enum AUTH_TYPES {
  google = 'google',
  discord = 'discord'
}
