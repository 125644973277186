import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  UserSignInRequest,
  UserSignUpRequest,
  DiscordSignUpRequest,
  AuthTokens,
  StatusResponse,
  Response
} from '../intarfaces';
import { AUTH_TYPES, AUTH_METHODS } from '../../shared/enums';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set accessToken(credentials: string) {
    localStorage.setItem('accessToken', credentials);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken')!.toString() : '';
  }

  set refreshToken(credentials: string) {
    localStorage.setItem('refreshToken', credentials);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken')!.toString() : '';
  }

  set utmSourceId(utmSourceId: string) {
    localStorage.setItem('utmSourceId', utmSourceId);
  }

  get utmSourceId(): string {
    return localStorage.getItem('utmSourceId') ? localStorage.getItem('utmSourceId')!.toString() : '';
  }

  set returnUrl(returnUrl: string) {
    localStorage.setItem('returnUrl', returnUrl);
  }

  get returnUrl(): string {
    return localStorage.getItem('returnUrl') ? localStorage.getItem('returnUrl')!.toString() : '';
  }

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    if (this.refreshToken) {
      this.isLoggedIn$.next(true);
    }
  }

  successAuthorize(tokenData: AuthTokens) {
    this.refreshToken = tokenData.refreshToken;
    this.accessToken = tokenData.accessToken;
    this.isLoggedIn$.next(true);
    this.router.navigateByUrl('/profile');
  }

  handleRedirect() {
    if (this.returnUrl !== '') {
      const returnUrl = this.returnUrl;
      localStorage.removeItem('returnUrl');
      window.location.href = returnUrl;
    }
  }

  refreshTokenRequest(): Observable<Response<AuthTokens>> {
    const refreshToken: string = this.refreshToken;
    console.log('Make refresh token request...');
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/refresh`, { refreshToken });
  }

  async hashPassword(password: string, salt: string): Promise<string> {
    const encoder = new TextEncoder();
    const passwordKey = encoder.encode(password);
    const saltKey = encoder.encode(salt);

    // Import the password as a CryptoKey object
    const key = await crypto.subtle.importKey('raw', passwordKey, { name: 'PBKDF2' }, false, ['deriveBits']);

    // Derive the key using PBKDF2
    const derivedBits = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        salt: saltKey,
        iterations: 100000, // number of iterations
        hash: 'SHA-256'
      },
      key,
      256 // result hash length in bits
    );

    // Convert the derived bits to a hex string
    const hashArray = Array.from(new Uint8Array(derivedBits));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  getGoogleAuthUrl(method: AUTH_METHODS): string {
    const authUrl = environment.oauth[AUTH_TYPES.google].authUrl;
    const clientId = environment.oauth[AUTH_TYPES.google].clientId;
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, method);
    const state = this.generateState();

    return `${authUrl}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
  }

  getDiscordAuthUrl(method: AUTH_METHODS): string {
    const authUrl = environment.oauth[AUTH_TYPES.discord].authUrl;
    const clientId = environment.oauth[AUTH_TYPES.discord].clientId;
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, method);

    return `${authUrl}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`;
  }

  getRedirectUrl(type: AUTH_TYPES, method: AUTH_METHODS): string {
    return environment.oauth[type][method];
  }

  generateState(): string {
    return Math.random().toString(36).substring(2);
  }

  sendLoginRequest(userData: UserSignInRequest): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/password`, userData);
  }

  sendGoogleLoginRequest(code: string): Observable<Response<AuthTokens>> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, AUTH_METHODS.signin);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/google`, {
      code: code,
      redirectUri: redirectUri
    });
  }

  sendDiscordLoginRequest(code: string): Observable<Response<AuthTokens>> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, AUTH_METHODS.signin);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/discord`, {
      code: code,
      redirectUri: redirectUri
    });
  }

  sendRegisterRequest(data: UserSignUpRequest): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/user/sign-up/password`, data);
  }

  sendGoogleSignupRequest(code: string): Observable<Response<AuthTokens>> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, AUTH_METHODS.signup);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/user/sign-up/google`, {
      code: code,
      redirectUri: redirectUri
    });
  }

  sendDiscordSignupRequest(data: DiscordSignUpRequest): Observable<Response<AuthTokens>> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, AUTH_METHODS.signup);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/user/sign-up/discord`, {
      ...data,
      ...{ redirectUri: redirectUri }
    });
  }

  setPasswordRequest(data: string): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/password`,
      { password: data },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  connectGoogleRequest(code: string): Observable<StatusResponse> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, AUTH_METHODS.connect);
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/google`,
      { code: code, redirectUri: redirectUri },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  connectDiscordRequest(code: string): Observable<StatusResponse> {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, AUTH_METHODS.connect);
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/discord`,
      { code: code, redirectUri: redirectUri },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  disconnectGoogleRequest(): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/remove-sign-in/google`,
      {},
      {
        headers: this.authorizationHeader()
      }
    );
  }

  disconnectDiscordRequest(): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/remove-sign-in/discord`,
      {},
      {
        headers: this.authorizationHeader()
      }
    );
  }

  logout() {
    this.clearData();
    this.router.navigateByUrl('/');
  }

  clearData() {
    this.isLoggedIn$.next(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  authorizationHeader() {
    return new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);
  }
}
