import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { ValidatorsService } from '../../shared/services/validators.service';

@Component({
  selector: 'signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss']
})
export class SigninPageComponent implements OnInit {
  @AutoUnsubscribe()
  authSub: Subscription | undefined;

  form: FormGroup = new FormGroup<any>({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private router: Router,
    public validatorsService: ValidatorsService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authSub = this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigateByUrl('profile');
        }
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.authService.hashPassword(this.form.value.password!, this.form.value.email!).then((hash) => {
      const userSignInRequest = { ...this.form.value, password: hash };
      this.authService.sendLoginRequest(userSignInRequest).subscribe((response) => {
        if (response.success && response.data) {
          this.authService.successAuthorize(response.data);
        }
      });
    });
  }
}
