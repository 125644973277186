import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);


  return authService.isLoggedIn$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        return true;
      } else {
        router.navigateByUrl('/');
        return false;
      }
    }),
    catchError((err) => {
      console.log('AuthGuard err:', err);
      return of(router.createUrlTree(['/']));
    })
  );
};
