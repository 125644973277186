<div class="auth">
  <img *ngIf="(theme$ | async) === 'dark'; else light" class="auth__logo" src="assets/logo.svg" alt="Gaimin" />
  <ng-template #light>
    <img class="auth__logo" src="assets/light-logo.svg" alt="Gaimin" />
  </ng-template>

  <div class="auth__title">
    <h1>Join GAIMIN</h1>
    <h2>
      Already have an account?
      <span routerLink="/login">Sign in</span>
    </h2>
  </div>

  <form [formGroup]="form" class="form">
    <div class="form__field-group">
      <label for="email"> Email </label>
      <input formControlName="email" class="form__field" type="text" placeholder="" id="email" />

      <span *ngIf="form.get('email')!.hasError('required') && form.get('email')!.dirty" class="form__error">
        Email should be present
      </span>
      <ng-container
        *ngIf="
          form.get('email')!.hasError('minlength') || form.get('email')!.hasError('maxlength');
          else email_pattern
        ">
        <span class="form__error">
          Email should have
          <mark>{{ emailMinLength }} - {{ emailMaxLength }}</mark>
          characters. You currently typed
          <mark>{{ form.get('email')!.value!.length }}</mark>
        </span>
      </ng-container>

      <ng-template #email_pattern>
        <ng-container *ngIf="form.get('email')!.hasError('pattern')">
          <span class="form__error">Valid email address required</span>
        </ng-container>
      </ng-template>
    </div>

    <div class="form__field-group">
      <label for="firstName"> First Name </label>
      <input formControlName="firstName" class="form__field" type="text" placeholder="" id="firstName" />
      <ng-container *ngIf="form.get('firstName')!.invalid && form.get('firstName')!.dirty">
        <span *ngIf="form.get('firstName')!.hasError('required')" class="form__error">
          First Name should be present
        </span>

        <ng-container
          *ngIf="
            form.get('firstName')!.hasError('minlength') || form.get('firstName')!.hasError('maxlength');
            else firstName_pattern
          ">
          <span class="form__error"
            >First Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ form.get('firstName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #firstName_pattern>
          <span *ngIf="form.get('firstName')!.hasError('pattern')" class="form__error">
            First Name should have only latin characters, hyphen and <mark>1</mark> space. It must start and end with a
            Latin character.
          </span>
        </ng-template>
      </ng-container>
    </div>
    <div class="form__field-group">
      <label for="lastName"> Last Name</label>
      <input formControlName="lastName" class="form__field" type="text" placeholder="" id="lastName" />
      <ng-container *ngIf="form.get('lastName')!.invalid && form.get('lastName')!.dirty">
        <span *ngIf="form.get('lastName')!.hasError('required')" class="form__error">
          Last Name should be present
        </span>

        <ng-container
          *ngIf="
            form.get('lastName')!.hasError('minlength') || form.get('lastName')!.hasError('maxlength');
            else lastName_pattern
          ">
          <span class="form__error"
            >Last Name should have <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark> characters. You currently
            typed <mark>{{ form.get('lastName')!.value!.length }}</mark></span
          >
        </ng-container>

        <ng-template #lastName_pattern>
          <ng-container
            ><span *ngIf="form.get('lastName')!.hasError('pattern')" class="form__error">
              Last Name should have only latin characters, hyphen and <mark>1</mark> space. It must start and end with a
              Latin character.
            </span></ng-container
          >
        </ng-template>
      </ng-container>
    </div>
    <div class="form__field-group">
      <label for="password"> Password</label>
      <div class="password-field">
        <input
          formControlName="password"
          [type]="validatorsService.isPasswordVisible ? 'text' : 'password'"
          class="form__field"
          id="password"
          #password />
        <span
          (click)="validatorsService.togglePasswordVisibility()"
          class="icon"
          [ngClass]="{
            'icon-Closed-Eye': !validatorsService.isPasswordVisible,
            'icon-Eye': validatorsService.isPasswordVisible
          }"></span>
      </div>

      <ng-container *ngIf="passwordControl.invalid && passwordControl.dirty">
        <span *ngIf="passwordControl.hasError('required')" class="form__error"> Password should be present </span>

        <ng-container
          *ngIf="
            passwordControl.hasError('minlength') || passwordControl.hasError('maxlength');
            else password_allowedPattern
          ">
          <span class="form__error">
            Password should have
            <mark>{{ validatorsService.passwordMinLength }} - {{ validatorsService.passwordMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ passwordControl.value!.length }}</mark>
          </span>
        </ng-container>

        <ng-template #password_allowedPattern>
          <ng-container *ngIf="passwordControl.hasError('allowedPattern'); else password_complexity">
            <span class="form__error">{{ validatorsService.allowedPatternError }}</span>
          </ng-container>
        </ng-template>

        <ng-template #password_complexity>
          <ng-container *ngIf="passwordControl.hasError('complexity')">
            <span class="form__error">{{ validatorsService.complexityError }}</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <div class="form__field-group">
      <mat-checkbox formControlName="agreement" color="primary">
        <ng-container *ngIf="legalLinks.tos; else no_tos">
          By signing up, you agree to our <a [href]="legalLinks.privacyPolicy" target="_blank">Privacy Policy</a>,
          <a [href]="legalLinks.eula" target="_blank">End User License Agreement (EULA)</a> and
          <a [href]="legalLinks.tos" target="_blank">Terms and Conditions</a>
        </ng-container>

        <ng-template #no_tos>
          By signing up, you agree to our <a [href]="legalLinks.privacyPolicy" target="_blank">Privacy Policy</a> and
          <a [href]="legalLinks.eula" target="_blank">End User License Agreement (EULA)</a>
        </ng-template>
      </mat-checkbox>
    </div>

    <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Sign up</button>
  </form>

  <div class="auth__divider"><span>OR</span></div>

  <app-socials-login [isRegisterForm]="true"></app-socials-login>

  <app-loader></app-loader>
</div>
