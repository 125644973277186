<mat-dialog-content>
  <button class="close" title="Close"><span class="icon-Close" (click)="close()"></span></button>
  <div class="popup">
    <img *ngIf="dialogData.imageSrc" [src]="dialogData.imageSrc" alt="error" />
    <div class="popup__text">
      <h2 class="popup__header">Something went wrong</h2>
      <p>
        {{ dialogData.error }}
      </p>
      <p>If the problem continues, please get in touch with Technical Support <a href="mailto:technical_support@gaimin.io">technical_support@gaimin.io</a></p>
    </div>
  </div>
</mat-dialog-content>
